<template>
  <van-nav-bar
  title="变更手机号"
  left-arrow
  @click-left="onGoBack"
  />
  <div style="margin-top:50px">
    <van-form @submit="modifyphone" class='register'>
      <van-field
        v-model="newphone"
        type="tel"
        name="newphone"
        size=large
        label="新手机号："
        label-align='right'
        placeholder="请输入新手机号"
        :rules="[{ validator:validatorphone,required: true, message: '请输入新手机号！' }]"
      />
    <van-button :disabled='isdisable' :loading='isloading' type="primary" round block style="width:50%;margin:50px auto 0 auto" native-type="submit" >变更手机号</van-button>
    </van-form>
  </div>
</template>

<script>
import { ref,reactive,getCurrentInstance,onMounted} from 'vue';
import { useRouter,useRoute } from 'vue-router'
import { Toast } from 'vant';
import { useUserStore } from '@/store/user.ts'

const base_url=process.env.VUE_APP_API_PORT_BASE_URL
export default {
  components: {
    [Toast.name]: Toast,
  },

  setup() {
    const  newphone=ref('');
    
    const  isdisable = ref(false);
    const  isloading = ref(false);
   
    const router = useRouter()
    const validatorphone=()=>{
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
			if (newphone.value == '' || newphone.value.length <= 10 || !reg.test(newphone.value)) {
				return  '手机号格式不正确，请检查！'
      }
    };
    
    let { proxy } = getCurrentInstance();
    const userStore = useUserStore()
    
    const modifyphone=()=>{
      
      isdisable.value = true;
      isloading.value = true;

      let params = {data: {}}
      params={
        id :userStore.userInfo.id,
        oldPhoneNum:userStore.userInfo.phone,
        newPhoneNum:newphone.value
      }
      let tokens={'Authorization':userStore.userInfo.token}
      proxy.$axios.post(base_url+'/user/modifyUserPhoneNum',params,{headers:tokens})
      .then(res=>{
        if(res.data.code==200){
          Toast(res.data.msg);
          userStore.setUserInfo({})
          router.push('/login');
        }else{
          Toast(res.data.msg);
          //Toast('密码手机失败，请确认手机是否正确。');
          isdisable.value = false;
          isloading.value = false;
        }
      })
    };


    return {
      newphone,
      isdisable,
      isloading,
      validatorphone,
      modifyphone,
    };
  }

  
};
</script>

<style lang="less">

.edit-icon {
    font-size: 16px;
    line-height: inherit;
  }

</style>
